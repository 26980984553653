@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}


.cursor-blink {
  animation: blink 0.7s step-end infinite;
  background-color: black;
  border-color: black;
  width: 7px;
  height: 10px;
  border-radius: 100%;
}