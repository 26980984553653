@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  background-color: #bae0ff;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 640px) {
  .input-table th:first-child {
    padding-left: 0px;
  }
  .input-table th:last-child {
    padding-right: 0px;
  }
  .input-table td:first-child {
    padding-left: 0px;
  }
  .input-table td:last-child {
    padding-right: 0px;
  }
}

input::-webkit-calendar-picker-indicator {
  opacity: 0;
}
